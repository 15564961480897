<template>
    <div class="view_container">
        
        <div id="content_main">
            <div id="category_content">
                <div id="category_content_title">
                    {{ languageData.trgovina_izbira_kategorije }}
                </div>
                
                 <category-item :data="item" v-for="(item, index) in categories" :key="index"></category-item>
            </div>

            <div id="offer_content">
                <div id="offer_content_title">
                    {{ languageData.trgovina_ponudba }}
                </div>
                
                <offer-item :data="item" v-for="(item, index) in filterOfferItems" :key="index"></offer-item>

            </div>
        </div>

        <div id="content_footer">
            <div class="footer_btn_item">
                <button class="btn_footer btn_footer_red" @click="goBack()"><i id="footer_btn_position_left" class="fas fa-times"></i>&nbsp;&nbsp;{{ languageData.preklici_nazaj }}</button>
            </div>
            <div class="footer_btn_item">
                <table id="table_amount" width="100%;" height="92px;">
                    <tbody>
                        <tr id="title">
                            <td>
                                {{ languageData.skupaj_za_placilo }}
                            </td>
                        </tr>
                        <tr id="amount">
                            <td>
                                <!--<transition name="fade" mode="out-in">
                                    <div :key="getCartItemsTotal">
                                        {{ getCartItemsTotal }}<span class="currency">€</span>
                                    </div>
                                </transition>-->
                                {{ getCartItemsTotal }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="footer_btn_item">
                <!--<button class="btn_footer btn_footer_blue" @click="goToPayment()">{{ languageData.na_placilo }} <i id="footer_btn_position_right" class="fas fa-chevron-right"></i></button>-->
                <button class="btn_footer btn_footer_green" @click="goPickDates()">{{ languageData.nadaljuj }} <i id="footer_btn_position_right" class="fas fa-chevron-right"></i></button>
            </div>
        </div>

    </div>
</template>

<script>

import CategoryItem from '@/components/CategoryItem.vue'
import OfferItem from '@/components/OfferItem.vue'
import {formatPrice} from '@/helpers/utilities.js'
import mitt from 'mitt'

export default {
    components: {
        CategoryItem,
        OfferItem
    },
        

    data: () => ({
        emitter: mitt(),
        sum: 0.00,
        category_title: "Izberi željeno kategorijo",
        offer_title: "Ponudba",
        /*categories: [
            {
                id: 1,
                name: 'Vstopnice',
                icon: 'ticket',
                color: '#007CA4',
                type: 'vstopnica',
                items: [
                    {
                        name: 'Vstopnica Vintgar',
                        category: 'Odrasli',
                        price: 10.00,
                        color: '#007CA4'
                    },
                    {
                        name: 'Vstopnica Vintgar',
                        category: 'Študenti / Upokojenci',
                        price: 7.00,
                        color: '#007CA4'
                    },
                    {
                        name: 'Vstopnica Vintgar',
                        category: 'Otroci 6 - 15 let',
                        price: 2.00,
                        color: '#007CA4'
                    },
                    {
                        name: 'Vstopnica Vintgar',
                        category: 'Otroci do 6 let',
                        price: 1.00,
                        color: '#007CA4'
                    },

                    {
                        name: 'Vstopnica Vintgar',
                        category: 'Pes',
                        price: 3.00,
                        color: '#007CA4'
                    }
                ]
            },

            {
                id: 2,
                name: 'Parkiranje',
                icon: 'parking_green',
                color: '#45A735',
                type: 'parking',
                items: [
                    {
                        name: 'Parkiranje',
                        category: 'Osebno vozilo',
                        price: 5.00,
                        color: '#45A735'
                    },
                    {
                        name: 'Parkiranje',
                        category: 'Avto dom',
                        price: 10.00,
                        color: '#45A735'
                    },
                    {
                        name: 'Parkiranje',
                        category: 'Bus',
                        price: 15.00,
                        color: '#45A735'
                    },
                    
                ]
            },

            {
                id: 3,
                name: 'Avtobusni prevozi',
                icon: 'school_bus_orange',
                color: '#FFA800',
                type: 'prevoz',
                items: [
                    {
                        name: '"Vintgar" prevoz nazaj na izhodišče',
                        category: '',
                        price: 6.00,
                        color: '#FFA800'
                    }
                ]
            }
        ],*/

        /*offerItems: [
            {
                id: 1,
                name: 'Vstopnica Vintgar',
                category: 'Odrasli',
                price: 10.00,
                color: '#007CA4',
                type: 'vstopnica'
            },
            {
                id: 2,
                name: 'Vstopnica Vintgar',
                category: 'Študenti / Upokojenci',
                price: 7.00,
                color: '#007CA4',
                type: 'vstopnica'
            },
            {
                id: 3,
                name: 'Vstopnica Vintgar',
                category: 'Otroci 6 - 15 let',
                price: 2.00,
                color: '#007CA4',
                type: 'vstopnica'
            },
            {
                id: 4,
                name: 'Vstopnica Vintgar',
                category: 'Otroci do 6 let',
                price: 1.00,
                color: '#007CA4',
                type: 'vstopnica'
            },

            {
                id: 5,
                name: 'Vstopnica Vintgar',
                category: 'Pes',
                price: 3.00,
                color: '#007CA4',
                type: 'vstopnica'
            },
            {
                id: 6,
                name: 'Parkiranje',
                category: 'Osebno vozilo',
                price: 5.00,
                color: '#45A735',
                type: 'parking'
            },
            {
                id: 7,
                name: 'Parkiranje',
                category: 'Avto dom',
                price: 10.00,
                color: '#45A735',
                type: 'parking'
            },
            {
                id: 8,
                name: 'Parkiranje',
                category: 'Bus',
                price: 15.00,
                color: '#45A735',
                type: 'parking'
            },
            {
                id: 9,
                name: '"Vintgar" prevoz nazaj na izhodišče',
                category: '',
                price: 6.00,
                color: '#FFA800',
                type: 'prevoz'
            }
        ]*/
    }),

    computed: {
    getSum() {
            return this.sum.toFixed(2).toLocaleString('pt-BR')
        },

        getCartItemsTotal() {
            //console.log(this.$store.getters.price)
            //var number = 350.0;
            //console.log("Aggregated");
            //console.log(this.$store.getters.cartItemsAggregated);
            //console.log("=================================")


            //console.log(new Intl.NumberFormat('de-DE').format(number));
            return formatPrice(this.$store.getters.price)
            //return new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 2, minimumFractionDigits: 2 }).format(this.$store.getters.price)
        },

        filterOfferItems() {
            let offerItems = [];

            this.$store.getters.categories.forEach(category => {
               category.products.forEach(product => {
                   offerItems.push({
                       id: product.id,
                       name: product.name,
                       name_en: product.name_en,
                       category: (product.category != null) ? product.category.name : "",
                       category_en: (product.category != null) ? product.category.name_en : "",
                       price: product.price_with_tax,
                       color: product.color,
                       type: product.type,
                       tax_rate: product.tax_rate.value
                   });
                   
               }) 
            });

            return offerItems;
        },

        languageData() {
            return this.$store.getters.languageData
        },

        categories() {
            return this.$store.getters.categories
        }
    },

    methods: {
        goBack() {
            //izprazni še košarico
            this.$store.dispatch('EMPTY_CART');
            this.$router.push({
                name: 'Home'
            })

            //pobriši izbrane datume
            this.$store.dispatch('TRUNCATE_SELECTED_DATES');
        },

        goToPayment() {
            this.$router.push({
                name: 'Cart'
            })
        },

        goPickDates() {
            this.$router.push({
                name: 'SelectDate'
            })
        }
    },

    created() {

    },

    mounted() {
        //window.console.log("Dates:")
        //window.console.log(this.$store.getters.dates)
        //window.console.log("Categories:")
        //window.console.log(this.$store.getters.categories)
    },

    unmounted() {

    }
}

</script>

<style scoped>
    #table_amount #title {
        
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #656565;
        font-weight:600;
    }

    #table_amount #amount {
        text-align:center;
        font-size:38px;
        color: #007CA4;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #table_amount {
        background: #ECECEC;
    }

    #category_content {
        height:20vh;
        /*border: 1px solid purple;*/
        margin:5px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        /*justify-content: space-between;*/
        justify-content: center;
    }

    #offer_content {
        height:53vh;
        /*border: 1px solid green;*/
        margin:5px;
        display:flex;
        flex-wrap: wrap;
        flex-direction: row;
        /*justify-content: space-between;*/
        justify-content: space-between;
        align-items:flex-start;
    }

    #category_content_title {
        width: 100%;
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
    }

    #offer_content_title {
        width: 100%;
        text-align:center;
        font-size:18px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        font-weight:600;
        height:30px;
        border-bottom: 1px solid #007CA4;
    }
    
    .currency {
        margin-left: 3px;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    #footer_btn_position_right {
        /*position:relative;
        right: -50px;*/
    }

    #footer_btn_position_left {
        /*position:relative;
        right: 30px;*/
    }

    #content_main {
    min-height:76vh;
    /*border:1px solid orange;*/
    margin:5px;
}


</style>