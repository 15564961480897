<template>
    <div class="category_item" :id="'category_item_' + data.id" @click="selectItem()">
        <div class="category_item_content">
            <div class="category_item_icon">
                <img  :src="getIcon(data.image)" :alt="data.name" />
            </div>
            <div class="category_item_name">
                {{ itemName() }}
            </div>
                <div :id="borderItemId()" class="category_item_border_bottom"></div>
        </div>
        
    </div>
</template>

<script>

import ticket from '@/assets/icons/ticket.png'
import schoolBusOrange from '@/assets/icons/school_bus_orange.png'
import parking from '@/assets/icons/parking_green.png'

export default {
    name: 'category-item',
    props: ['data'],

    data: () => ({
        clicked: false
    }),

    watch: {
        clicked(value) {
            if(value === true) {
                document.querySelector("#category_item_" + this.data.id).style.background = "rgba(142, 142, 142, 0.2)";
                this.$store.dispatch('ADD_CATEGORY', this.data.type)
            } else {
                document.querySelector("#category_item_" + this.data.id).style.background = "#fff";
                this.$store.dispatch('REMOVE_CATEGORY', this.data.type)
            }

            console.log(this.$store.getters.selectedCategories)
        }
    },

    computed: {
        selectedLanguage() {
            return this.$store.getters.language
        },

    },
    
    methods: {
        getIcon(icon) {
            if(icon === 'ticket') {
                return ticket
            }

            if(icon === 'school_bus_orange') {
                return schoolBusOrange
            }

            return parking;
        },

        selectItem() {
            this.clicked = !this.clicked

        },

        borderItemId() {
            return "border_item_" + this.data.id
        },

        itemName() {
            let selectedLanguage = this.$store.getters.language
            if(selectedLanguage == 'sl') {
                return this.data.name
            }

            return this.data.name_en
        }

    },

    created() {

    },

    mounted() {
        //window.console.log("category-item:")
        //console.log(this.data)
        // window.console.log("Name: " + this.data.name)
        // window.console.log("Icon: " + this.data.image)
        document.querySelector("#border_item_" + this.data.id).style.borderBottom = "2px solid " + this.data.color

    },

    unmounted() {

    }
}

</script>

<style scoped>
    .category_item {
        height:113px;
        /*width: 30%;*/
        width: 100%;
        border:1px solid#8E8E8E;
    }

    .category_item_content {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
    }

    .category_item_icon {
        padding-top:5px;
        width:100%;
        height: 51px;
        text-align:center;
    }

    .category_item_name {
        width:100%;
        text-align:center;
        padding-top:10px;
        font-size:16px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    .category_item_border_bottom {
        margin-left:auto;
        margin-right:auto;
        margin-top:10px;
        width:25%;
        /*border-bottom:2px solid black;*/
    }

    img {
        width:51px;
        height:51px;
    }

    #abc {
        position:relative;
        right:5px;
        top:5px;
        width:15px;
    }
</style>