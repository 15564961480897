<template>
    <div v-wave="getRippleData()" class="offer_item" :id="'offer_item_' + data.id" @click="selectItem()">
        <div class="offer_item_content">
            <div class="offer_item_title">
                {{ getItemName() }}
            </div>
            <div class="category_item_name">
                {{  getCategoryName() }}
            </div>
            <div class="offer_item_price">
                {{ data.price }}<span class="currency">€</span>
            </div>
        </div>
    </div>
</template>

<script>

import Ripple from './Ripple.vue'
import { hexToRgba } from '@/helpers/utilities.js'

export default {
    name: "offer-item",
    components: {
    },
    props: ['data'],
    data: () => ({
        clicked: false
    }),

    watch: {
        clicked(value) {
            if(value === true) {
                document.querySelector("#offer_item_" + this.data.id).style.background = "rgba(142, 142, 142, 0.2)";
            } else {
                document.querySelector("#offer_item_" + this.data.id).style.background = "#fff";
            }
        }
    },

    computed: {

    },

    methods: {
        selectItem() {
            //this.clicked = !this.clicked
            this.$store.dispatch('ADD_TO_CART', this.data)
            setTimeout(() => {
                //console.log(this.$store.getters.cartItems);
            }, 500)

        },

        getRippleColor() {
            return hexToRgba(this.data.color, 0.3)
        },

        getRippleData() {
            return {
                color: this.data.color,
                startingOpacity: 0.9,
                easing: 'ease-in'
            }
        },

        getItemName() {

            let selectedLanguage = this.$store.getters.language
            if(selectedLanguage == 'sl'){
                return this.data.name
            }

            return this.data.name_en
        },

        getCategoryName() {
            let selectedLanguage = this.$store.getters.language
            if(selectedLanguage == 'sl'){
                return this.data.category
            }

            return this.data.category_en
        }
    },

    created() {
        //window.console.log(this.data)
        //console.log(hexToRgba(this.data.color, 0.7))
        //console.log(this.getRippleData())
    },

    mounted() {
        document.querySelector("#offer_item_" + this.data.id).style.borderLeft = "5px solid " + this.data.color
    },

    unmounted() {

    }
}

</script>

<style scoped>

    .offer_item {
        height:113px;
        /*width: 30%;*/
        width: 100%;
        border:1px solid #8E8E8E;
        margin-top:-70px;
    }

    .offer_item_content {
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
    }

    .offer_item_title {
        width:100%;
        text-align:center;
        padding-top:10px;
        font-size:16px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    .category_item_name {
        width:100%;
        text-align:center;
        padding-top:5px;
        font-size:16px;
        font-weight:500;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
    }

    .offer_item_price {
        width:100%;
        text-align: center;
        padding-top:15px;
        font-size:20px;
        font-weight:600;
        font-family: 'Roboto', sans-serif;
        font-style:normal;
        color: #007CA4;
    }

    .currency {
        margin-left:3px;
    }

</style>